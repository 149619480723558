import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import styled, { css } from "styled-components"
import tw from "tailwind.macro"
import Layout from "../components/Layout"
import { PrimaryLink } from "../elements/shared/buttons"
import { HeaderText3, GrayTextWrapper } from "../elements/shared/texts"

const Container = styled.div`
  ${tw`flex items-center my-16`}
  min-height: 100vh;
  

  div, a {
    margin: 0 auto;
  }

  a {
    width: 200px;
  }

  h1 , p{
    text-align: center;
    margin: 0 50px;
  }


`

const SVG = styled("img")`
  max-width: min(75%, 60vh);
  z-index: 1;
  margin: 50px auto;

`



const NotFoundPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file: file(relativePath: { eq: "404.svg" }) {
          publicURL
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Container>
        <div>
        <HeaderText3>Oops, Not Found!</HeaderText3>
        <GrayTextWrapper>
          <p>Looks like the page you are looking for does not exist.</p>
        </GrayTextWrapper>
        <SVG src={data.file.publicURL} />
        <PrimaryLink to={`/`}>Go Home</PrimaryLink>
        </div>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
